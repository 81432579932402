html {
    min-height: 500px;
}

.flex {
    display: flex;
    padding: 80px 0;
    align-items: center;
    justify-content: center;

    min-height: calc(100vh - 402px);
}

.m-10 {
    margin: 10px;
}

.white-box {
    position: relative;
    padding: 30px;
    min-width: 0;

    background-color: hsl(0, 0%, 100%);
    box-shadow: 0 0 4px hsla(0, 0%, 0%, 0.1);
    border: 1px solid hsl(0, 0%, 87%);
    border-radius: 4px;
}

.bottom-text {
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
    font-size: 0.9em;

    a {
        color: hsl(164, 100%, 23%);

        &:hover {
            text-decoration: none;
            color: hsl(156, 62%, 61%);
        }
    }
}

.grey {
    color: hsl(0, 0%, 67%);
}

.find-account-page-container #find_account i {
    font-size: 0.8em;
}

.app-main {
    .login-page-header {
        font-size: 1.5em;
        font-weight: 300;
        margin: 0;
        height: 0;
        transform: translateX(-15px) translateY(-60px);
        text-align: left;
    }

    .login-page-container.dev-login {
        .login-page-header {
            height: auto;
            margin-top: 20px;
            transform: none;
            text-align: center;
        }

        h2 {
            font-size: 1em;
            font-weight: 400;
        }

        .group {
            display: inline-block;
            vertical-align: top;

            margin: 0 20px;
        }
    }

    &.forgot-password-container {
        font-weight: 400;
    }

    &.find-account-page-container {
        width: 500px;
        font-weight: 400;
    }

    &.goto-account-page-container {
        width: 500px;
        font-weight: 400;

        .realm-redirect-form {
            text-align: center;

            .inline-block {
                text-align: center;
            }
        }
    }

    &.confirm-continue-page-container {
        width: 400px;
        font-weight: 400;

        .white-box {
            min-width: 365px;
        }

        .form-inline {
            display: inline-block;
        }

        .form-inline .outline {
            border: 2px inset hsl(213, 23%, 25%);
            background-color: hsl(0, 0%, 100%);
            color: hsl(213, 23%, 25%);
            padding: 13px 20px 11px 20px;

            &:hover {
                background-color: hsl(213, 65%, 97%);
            }
        }

        p {
            font-size: 19px;
        }
    }
}

.login-page-container {
    &.dev-login {
        margin-top: 20px;
        border: none;

        .login-form {
            width: auto;
        }
    }

    .group {
        margin: 0 20px;
        display: inline-block;
    }

    input[type="submit"] {
        color: hsl(0, 0%, 67%);
        border: 1px solid hsl(0, 0%, 87%);
        border-radius: 4px;
        background-color: transparent;

        transition: color 0.3s ease, border 0.3s ease;

        &:hover {
            border-color: hsl(0, 0%, 53%);
            color: hsl(0, 0%, 27%);
        }

        &.btn-admin {
            border-color: hsl(170, 41%, 52%);
            color: hsl(170, 41%, 52%);

            &:hover {
                color: hsl(156, 62%, 61%);
                border-color: hsl(156, 62%, 61%);
            }
        }
    }
}

.new-style .login-page-container .alert,
.register-page-container .new-style .alert {
    margin: 0;
    text-align: left;
    font-size: 0.7em;
    font-weight: 600;
}

.register-page-container {
    &.closed-realm {
        .right-side {
            display: none;
        }

        .left-side {
            border-right: none;
        }

        .invite-required {
            display: block;

            color: hsl(0, 0%, 67%);
            font-weight: normal;
        }
    }

    .invite-required {
        display: none;
        line-height: 1;

        i {
            margin-right: 5px;
        }
    }
}

.find-account-page-container h3,
.forgot-password-container h3 {
    margin-top: 0;

    font-weight: 300;
    font-size: 2em;
}

.forgot-password-container {
    width: 503px;

    h3,
    form {
        margin-bottom: 0;
    }
}

.deactivated-realm-container {
    width: 503px;
}

.header {
    color: hsl(0, 0%, 27%);
    background-color: hsl(0, 0%, 100%);
    position: fixed;
    width: 100%;
    top: 0;

    .top-links a,
    .header-main .logo span {
        color: hsl(0, 0%, 27%);
    }
}

.register-form {
    &.new-style {
        text-align: left;
        color: hsl(0, 0%, 45%);
    }

    #errors {
        font-size: 0.8em;
        font-weight: 400;
        margin-bottom: 20px;
        margin-top: 10px;

        &:empty {
            margin-top: 0;
        }
    }

    button {
        margin-top: 22px;
    }

    .new-organization-button {
        margin-top: 25px;
    }
}

.account-accept-terms-page .terms-of-service .input-group {
    width: 450px;
    margin: 0 auto 10px;
}

.register-account {
    .terms-of-service .input-group,
    .default-stream-groups .input-group,
    .default-stream-groups p {
        width: 330px;
        margin: 0 auto 10px;
    }

    .terms-of-service .text-error {
        position: relative;
        display: block;
        top: -5px;
        padding: 0;
        height: 0;

        font-size: 0.7em;
        font-weight: 600;
    }
}

.account-creation {
    font-weight: 400;

    .pitch {
        margin-bottom: 0;
    }

    .alert.alert-info {
        padding: 8px 14px;
        text-align: left;

        font-size: 1em;
        line-height: 1.3;
    }

    .white-box p:last-of-type {
        margin-bottom: 0;
    }
}

.relative {
    position: relative;
}

.new-style {
    button {
        display: inline-block;
        vertical-align: top;
        padding: 13px 22px 13px 22px;

        font-family: "Source Sans 3", sans-serif;

        font-size: 1.2rem;
        font-weight: 400;
        box-sizing: border-box;
        outline: none;
        color: hsl(0, 0%, 100%);
        background-color: hsl(213, 23%, 25%);

        margin: 25px 0 5px;
        height: 50px;

        border: none;
        border-radius: 4px;

        transition: all 0.3s ease;

        &:hover {
            background-color: hsl(213, 33%, 31%);
        }

        &:active {
            background-color: hsl(214, 28%, 16%);
        }

        &:focus {
            outline: 3px solid hsl(213, 81%, 79%);
        }

        &.full-width {
            width: 100%;
        }
    }

    .alert-info {
        font-weight: 400;
    }
    .alert {
        &:not(.alert-info) {
            padding: 0;
            margin-bottom: 0;
            font-weight: 600;
            font-size: 0.7em;
            line-height: 1.2;
            background-color: transparent;

            border: none;
            color: hsl(0, 44%, 54%);
        }

        &.alert-error {
            text-align: left;
        }
    }

    .form-inline {
        margin: 0;
    }

    .get-started {
        font-size: 2.5rem;
        text-align: center;
        color: hsl(0, 0%, 40%);
        margin-bottom: 20px;
    }

    .right-side .alert {
        max-width: 328px;
    }

    .input-box {
        position: relative;
        display: inline-block;
        vertical-align: top;

        input[type="text"],
        input[type="email"],
        input[type="password"] {
            padding: 10px 32px 10px 12px;
            margin: 25px 0 5px;

            font-family: "Source Sans 3", sans-serif;
            font-size: 1.2rem;
            line-height: normal;
            height: auto;

            width: 280px;

            border: 1px solid hsl(0, 0%, 87%);
            box-shadow: none;
            border-radius: 4px;

            transition: border 0.3s ease;

            &:focus:invalid {
                box-shadow: none;
                color: hsl(0, 0%, 27%);
            }

            &:focus {
                border: 1px solid hsl(0, 0%, 53%);
            }
        }

        input[type="email"] {
            margin-bottom: 10px;
        }

        label {
            position: absolute;
            top: 0;
            left: 0;

            margin-top: 1px;

            transition: all 0.3s ease;
        }

        &.moving-label {
            input[type="text"]:invalid + label,
            input[type="email"]:invalid + label,
            input[type="password"]:invalid + label {
                transform: translateY(35px) translateX(14px);
                font-size: 1.2rem;
                font-weight: 400;
                color: hsl(0, 0%, 67%);

                pointer-events: none;
            }
        }

        label,
        input[type="text"]:focus + label,
        input[type="email"]:focus + label,
        input[type="password"]:focus + label,
        input[type="text"]:valid + label,
        input[type="email"]:valid + label,
        input[type="password"]:valid + label {
            left: 0;
            transform: translateY(0) translateX(0);
            pointer-events: auto;

            font-size: 1rem;
            font-weight: 600;
            color: hsl(0, 0%, 27%);
        }

        p.text-error {
            display: block;
            padding: 0;

            color: hsl(1.1, 44.7%, 50.4%);
            font-size: 0.7em;
            font-weight: 600;
            text-align: left;
            line-height: 1.2;

            position: relative;
            left: 1px;
        }

        label.text-error {
            display: block;

            top: 0;
            right: 0;
            text-align: right;
            color: hsl(1.1, 44.7%, 50.4%);
            font-size: 0.7em;
            font-weight: 600;
            padding-left: 0;
        }
    }

    .lead {
        margin: 0;
    }

    .inline-block {
        text-align: left;
    }

    .contact-admin p.invite-hint {
        font-size: 1.3rem;
        margin-top: 0.8rem;
        text-align: center;
    }
}

#login_form {
    .input-box {
        display: block;
    }

    .loader {
        display: none;
        vertical-align: top;
        position: relative;

        height: 30px;
        margin-top: -10px;
        top: 5px;
    }
}

/* -- /accounts/register/ page -- */
.portico-page {
    .pitch {
        h1 {
            margin-bottom: 5px;
        }

        p {
            font-weight: 400;
            color: hsl(0, 0%, 67%);
        }
    }

    .or {
        width: 100%;
        display: block;
        margin: 10px auto;
        color: hsl(0, 0%, 34%);
        font-weight: 600;
        text-align: center;
        position: relative;
        z-index: 1;

        &::before {
            content: " ";

            display: inline-block;
            position: absolute;
            width: calc(100% - 5px);
            top: calc(50% - 2px);
            left: 0;
            z-index: -1;

            border-bottom: 2px solid hsl(0, 0%, 87%);
        }

        span {
            background-color: hsl(0, 0%, 100%);
            padding: 0 5px;
        }
    }
}

.register-account .pitch,
.account-accept-terms-page .pitch {
    margin-bottom: 5px;
    text-align: center;
}

.split-view {
    .org-header {
        text-align: left;

        .info-box {
            display: inline-block;
            position: relative;
            margin: 20px 0 0 20px;
            width: calc(100% - 125px);

            /* full width minus the avatar + padding + borders */
            text-align: left;
        }
    }

    .left-side {
        width: 500px;
        border-right: 1px solid hsl(0, 0%, 93%);
        position: relative;
        left: -1px;

        .description {
            text-align: left;
            font-weight: normal;

            margin-top: 20px;
            margin-right: 10px;
        }

        + .right-side {
            border-left: 1px solid hsl(0, 0%, 93%);
            /* this is to make sure the borders of the left and right side overlap
               each other. */
            padding-left: 15px;
            margin-left: -5px;
        }
    }

    .right-side .form-inline {
        width: 328px;
    }
}

.split-view .right-side,
.split-view .left-side {
    display: inline-block;
    vertical-align: top;
}

.split-view .org-header .avatar,
.register-page-container .org-header .avatar {
    display: inline-block;
    vertical-align: top;

    width: 100px;
    height: 100px;
}

.info-box {
    .organization-name,
    .organization-path {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .organization-name {
        font-size: 1.5em;
        font-weight: 300;
        line-height: 1.2;
    }

    .organization-path {
        font-weight: 400;
        color: hsl(0, 0%, 47%);
        margin-top: 5px;
    }
}

.confirm-email-change-page .white-box {
    max-width: 500px;
}

button.login-social-button {
    width: 328px;
    padding-left: 35px;

    background-size: auto 60%;
    background-repeat: no-repeat;
    background-position: 13px 50%;
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.3), 0 0 5px hsla(0, 0%, 0%, 0.1);
    margin-left: 0;
    margin-top: 0;

    color: hsl(0, 0%, 34%);

    &:hover {
        background-color: hsl(0, 0%, 98%);
        box-shadow: 1px 2px 5px hsla(0, 0%, 0%, 0.2);
    }

    &:active {
        background-color: hsl(0, 0%, 93%);
        box-shadow: 0 1px 1px hsla(0, 0%, 0%, 0.3);
    }
}

button#login_auth_button_gitlab,
button#register_auth_button_gitlab {
    /* GitLab icon size looks a bit inconsistent with others. This fixes it. */
    background-size: auto 90%;
    background-position-x: 3px;
}

.login-page-container .right-side .actions,
.back-to-login-wrapper {
    margin: 20px 0 0;
    text-align: left;
}

.back-to-login-wrapper {
    line-height: 0;

    .back-to-login i {
        position: relative;
        top: 5px;

        font-size: 0.8em;
    }
}

#find-account-section {
    text-decoration: none;
    font-weight: 600;
    font-size: 0.8em;
    line-height: 1.5;
    vertical-align: top;

    #find-account-link {
        color: hsl(164, 100%, 23%);
    }
}

.split-view .actions a,
.back-to-login {
    color: hsl(164, 100%, 23%);
    text-decoration: none;
    font-weight: 600;
    font-size: 0.8em;
    line-height: 1.5;
    vertical-align: top;

    transition: color 0.2s ease;

    &:hover {
        text-decoration: none;
        color: hsl(156, 62%, 61%);
    }
}

#registration {
    width: auto;
    padding: 0;
    margin: 30px;

    fieldset {
        margin: 30px;

        .input-box:last-child {
            margin-bottom: 0;
        }
    }

    .info-box {
        margin: 10px 0 0 20px;

        .organization-name {
            max-width: 228px;
        }
    }

    .input-box {
        display: block;
        text-align: center;
        width: 330px;
        margin: 25px auto 10px;
        position: relative;

        &.with-top-space {
            margin-top: 50px;
        }

        &.full-width {
            width: calc(100% - 20px);
        }

        label {
            position: absolute;
            margin: 0;
            top: 0;
            left: 2px;

            &.static {
                width: 100%;
                text-align: left;
                position: static !important;
                margin-left: 2px;
            }
        }
    }

    & [for="realm_in_root_domain"] {
        font-weight: normal !important;
    }

    .register-button-box {
        text-align: center;
    }

    .register-button {
        margin: 25px auto 30px;
        width: 330px;
        border-radius: 4px;
    }

    #id_team_subdomain.subdomain {
        margin-top: 0;
        text-align: right;
        position: relative;
        padding-right: 10px;

        width: 150px;

        + .realm_subdomain_label {
            top: 15px;
            margin-left: 180px;
            display: inline-block;

            font-weight: normal;
            font-size: inherit;
        }
    }

    #subdomain_section {
        .inline-block {
            width: 100%;
        }

        .or {
            margin-top: 0;
        }
    }

    #id_email {
        font-weight: normal;
        margin: 2px;
        padding-top: 25px;
        text-align: left;
        overflow-wrap: break-word;
    }

    .help-text {
        width: 100%;
        max-width: none;
        margin: 2px 0;
        text-align: left;
        color: hsl(0, 0%, 47%);
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.2;
    }

    .help-inline {
        display: block;
    }

    legend {
        margin-bottom: 0;

        + .input-box {
            margin-top: 20px;
        }
    }

    .external-host {
        margin: 25px 0 0 -3px;
        padding: 11.5px 10px;

        font-weight: 400;
        font-size: 1.2rem;

        background-color: hsl(0, 0%, 87%);
    }

    .center-block .pitch {
        margin-bottom: 0;
    }

    .input-group {
        label {
            font-size: 1rem;

            &.inline-block {
                margin-top: -1px;
            }
        }

        &.radio {
            margin: 0;
            padding: 0;
        }
    }

    .org-url {
        margin-bottom: 5px !important;
    }
}

.static.org-url + #subdomain_section {
    margin-top: 0 !important;

    .or {
        display: none;
    }
}

#profile_info_section {
    #profile_avatar {
        border: 1px solid hsl(0, 0%, 80%);
        border-radius: 8px;
        width: 120px;
        height: 120px;
        margin: 0 auto 10px;
    }

    #profile_full_name {
        font-size: 1.2rem;
        font-weight: 400;
    }
}

#source_realm_select_section {
    position: relative;
    top: 15px;
    margin-bottom: 20px;
    font-size: 22px;
}

#source_realm_select {
    font-size: 1.2rem;
    height: 45px;
    width: 325px;
    &:focus {
        outline: none;
    }
}

.goto-account-page {
    #realm_redirect_subdomain {
        text-align: right;
        position: relative;
        padding-right: 10px;
    }

    #realm_redirect_external_host {
        font-size: 20px;
        top: 13px;
        left: 5px;
        position: relative;
    }

    #realm_redirect_description {
        top: 15px;
        position: relative;
    }

    #enter-realm-button {
        margin-top: 14px;
    }

    #find-account-section {
        margin-top: 20px;
        text-align: center;
        font-weight: 500;
        font-size: 0.9em;
    }

    .find-account-link {
        color: hsl(165, 100%, 14.7%);
    }
}

#choose_email {
    flex-direction: column;

    .white-box {
        min-width: 450px;
        padding: 30px 0 50px 0;
    }

    form {
        padding: 0;
        margin: 0;

        &.form-horizontal:hover {
            background-color: hsl(202, 56%, 91%);
            cursor: pointer;

            i.fa {
                color: hsl(203, 63%, 76%);
            }
        }
    }

    .choose-email-box {
        padding: 13px 0;
        margin: 0 30px;
        border-bottom: 1px solid hsl(0, 0%, 95%);
        display: flex;
        align-items: center;

        p {
            margin: 0;
            font-size: 0.8em;

            &:last-child {
                line-height: 1.2em;
            }
        }

        .email {
            font-size: 0.95em;
            font-weight: 500;
        }

        img,
        i {
            width: 35px;
            height: 35px;
            margin-right: 10px;
            border-radius: 3px;
        }

        i.fa {
            color: hsl(0, 0%, 67%);
            text-align: center;
            line-height: 38px;

            &::before {
                font-size: 30px;
            }
        }
    }
}

/* -- media queries -- */

@media (width <= 950px) {
    .split-view .left-side {
        width: 400px;
    }
}

@media (width <= 850px) {
    .split-view .left-side {
        width: 350px;
    }
}

@media (width <= 815px) {
    .flex {
        min-height: calc(100vh - 530px);
    }
}

@media (width <= 795px) {
    .register-account #registration {
        padding: 10px;
    }

    .register-page-container,
    .login-page-container {
        width: 400px;
        text-align: center;
    }

    .split-view {
        .org-header {
            .avatar {
                width: 50px;
                height: 50px;
            }

            .info-box {
                margin-top: 0;

                .organization-path {
                    margin-top: 0;
                }
            }
        }

        .left-side,
        .right-side {
            display: block;
            margin: 0 auto !important;
            max-width: 100%;
        }

        .left-side + .right-side {
            border-left: none;
            padding: 0;
            margin: 0 auto;
        }

        .left-side {
            border: none;
            margin-right: 0;
            min-height: 0;
            margin-bottom: 20px;
            width: 324px;

            .description {
                margin: 20px 0;

                a {
                    color: hsl(200, 100%, 36%);
                }
            }
        }

        .right-side {
            width: 324px;
        }
    }
}

@media (width <= 500px) {
    .new-style .get-started {
        font-size: 1.6em;
    }

    .app-main.register-page-container,
    .app-main.login-page-container,
    .app-main.find-account-page-container,
    .app-main.goto-account-page-container,
    .app-main.forgot-password-container {
        display: inline-block;
        padding: 20px;
        width: calc(100% - 40px);
    }

    .forgot-password-container form {
        .input-box {
            text-align: center;
        }

        button {
            width: 328px;
        }
    }

    .white-box {
        box-shadow: none;
    }

    .footer-navigation li,
    .footer-navigation li a {
        font-size: 1em;
    }

    .account-creation {
        font-weight: 400;
    }

    .flex {
        /* the header becomes smaller, so comp for that. */
        min-height: calc(100vh - 505px);
    }
}

@media (width <= 400px) {
    .flex {
        min-height: calc(100vh - 560px);
    }
}
